/* https://colorhunt.co/palette/0f044c141e61787a91eeeeee */
/* https://colorhunt.co/palette/0f044c141e61787a91eeeeee */
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Sofia+Sans&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary-color: #2E0249;
  --secondary-color: #6A0572;
  --third-color: #9A0F98;
  --fourth-color: #ac0bac;
  --white-color: #ffffff;
  --dark-color: #0f0018;
}

body {
  background: #0f0018;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

ul {
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}

p {
  margin-bottom: 0.5rem !important;
}

.main-page {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: var(--dark-color);
  position: relative;
}

.main-page .home-page {
  background-color: var(--dark-color);
  position: relative;
}

.main-page .home-page .first-box {
  height: 30vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)), url('https://c4.wallpaperflare.com/wallpaper/521/511/804/mountain-indonesia-caldera-horizon-wallpaper-preview.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 5px outset var(--primary-color);
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.2);
}

.main-page .home-page .avatar-box {
  display: flex;
  justify-content: center;
}

.main-page .home-page .avatar-box .avatar-cyrcle {
  margin-top: -75px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: var(--dark-color);
  border: 5px solid var(--secondary-color);
  animation: pulse 5s infinite;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    border-color: var(--secondary-color);
  }

  50% {
    border-color: var(--primary-color);
  }

  100% {
    border-color: var(--secondary-color);
  }
}

@keyframes pulse-and-rotate {
  0% {
    border-color: var(--fourth-color);
    transform: rotate(0deg);
  }

  50% {
    border-color: var(--primary-color);
    transform: rotate(180deg);
  }

  100% {
    border-color: var(--fourth-color);
    transform: rotate(360deg);
  }
}

.home-page .text-name {
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.home-page .text-with-line {
  display: inline-block;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 0px 5px 5px;
  border-bottom: 2px solid var(--secondary-color);
}

.home-page .social-media-box {
  justify-content: center;
}

.home-page .social-media-box .sosmed-box {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.home-page .bg-divider {
  height: 200px;
  background-image: url('./images/background-ask.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.divider {
  border-bottom: 2px outset var(--primary-color);
}

.bg-divider2 {
  justify-content: center;
  background-image: url('./images/bg-service3.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -5px;
  height: 20vh;
}

.home-page .service-box {
  justify-content: left;
  height: auto;
  background-color: var(--dark-color);
}

.home-page .service-box .service-title {
  justify-content: left;
  background-color: var(--dark-color);
}

.home-page .service-box .service-title .title-service-text {
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
}

.home-page .service-box .box-service-detail {
  width: 350px;
  height: 180px;
  background: #0f0016;
  box-shadow: inset 0 0 10px rgba(32, 0, 51, 0.705);
  border-radius: 10px;
  z-index: 1;
  border: 1px solid rgba(32, 0, 51, 0.705);
}

.home-page .service-box .box-service-detail .title-inside {
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
}

.home-page .service-box .box-service-detail .title-inside .website {
  border-left: #8617b9 3px solid;
}

.home-page .service-box .box-service-detail .title-inside .crypto {
  border-left: #f1bb09 3px solid;
}

.home-page .service-box .box-service-detail .title-inside .api {
  border-left: #008fa8 3px solid;
}

.home-page .service-box .box-service-detail .title-inside .bots {
  border-left: #a10023 3px solid;
}

.home-page .footer-box {
  height: 100px;
  justify-content: center;
  display: flex;
  border-top: 5px solid #1b0429c0;
  background-color: #0d0313;
}

.home-page .footer-box .text-footer {
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 17px;
  text-align: center;
}

.home-page .footer-box .text-footer .copyright {
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 17px;
  text-align: center;
  color: var(--white-color);
}

.home-page .footer-box .text-footer .copyright-reserved {
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 17px;
  text-align: center;
  color: var(--white-color);
}

.bg-animation {
  position: relative;
}

.star {
  position: absolute;
  background: white;
  border-radius: 50px;
  animation: move;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in 3s;
}

.type-1 {
  width: 1px;
  height: 1px;
  box-shadow: #6A0572 2px 2px 10px 3px;
}

.type-2 {
  width: 2px;
  height: 2px;
  box-shadow: #6A0572 2px 2px 10px 3px;
}

.type-3 {
  width: 3px;
  height: 3px;
  box-shadow: #6A0572 2px 2px 10px 3px;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-800px);
  }
}

.circle-1x {
  width: 100px;
  height: 100px;
  z-index: none;
  background: var(--primary-color);
  border-radius: 50px;
  position: absolute;
  right: 40px;
  top: 180px;
  filter: blur(3px);
  animation: blur-animation 5s infinite;
}

.circle-2x {
  width: 100px;
  height: 100px;
  z-index: none;
  background: #09555a;
  border-radius: 50px;
  position: absolute;
  left: 50px;
  bottom: 320px;
  filter: blur(3px);
  animation: blur-animation 5s infinite;
}

.circle-3x {
  width: 100px;
  z-index: none;
  height: 100px;
  background: #4c095a;
  border-radius: 50px;
  position: absolute;
  right: 55%;
  bottom: 20px;
  filter: blur(3px);
  animation: blur-animation 5s infinite;
}

@keyframes blur-animation {
  0% {
    filter: blur(5px);
    transform: scale(1.3);
  }

  50% {
    filter: blur(15px);
    transform: scale(1.5);
  }

  100% {
    filter: blur(5px);
    transform: scale(1.3);
  }
}

.text-custom {
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
}

.home-page .skill-box {
  justify-content: left;
  height: auto;
  background-color: var(--dark-color);
}

.home-page .skill-box .skill-title {
  justify-content: left;
  background-color: var(--dark-color);
}

.home-page .skill-box .skill-title .title-skill-text {
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
}

.title-container {
  text-align: left;
  position: relative;
}

.title-line {
  height: 4px;
  background-color: #412d46;
  display: inline-flex;
  vertical-align: middle;
}

.short-line {
  width: 2%;
}

.long-line {
  width: 15%;
}

.title-text {
  display: inline-block;
  padding: 0 10px;
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
}

.skills-bar-container {
  position: relative;
  width: 80%;
  min-width: 300px;
  list-style: none;
}

.skills-bar-container li {
  position: relative;
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}


.skills-bar-container li .progressbar-title {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-family: 'Sofia Sans', sans-serif;
}

.skills-bar-container li .progressbar-title h3 {
  display: inline-block;
}

/* Percent Styles */
.skills-bar-container li .progressbar-title .percent {
  position: absolute;
  right: 5px;
}

.skills-bar-container li .bar-container {
  background: #555;
  position: relative;
  width: 100%;
  height: 5px;
  margin-top: 5px;
  display: block;
  border-radius: 5px;
}

.skills-bar-container li .bar-container .progressbar {
  position: absolute;
  width: 0%;
  height: 100%;
  border-radius: 5px;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

#progress-html {
  animation-name: progress-html;
  animation-delay: 0.7s;
}

#progress-css {
  animation-name: progress-css;
  animation-delay: 1.4s;
}

#progress-javascript {
  animation-name: progress-javascript;
  animation-delay: 2.1s;
}

#progress-react {
  animation-name: progress-react;
  animation-delay: 2.8s;
}

#progress-python {
  animation-name: progress-python;
  animation-delay: 3.5s;
}

#progress-golang {
  animation-name: progress-golang;
  animation-delay: 3.5s;
}

/* Progress Bar Colors */
.progressred {
  background-color: #c0392b;
}

.progressblue {
  background-color: #1199ff;
}

.progressteal {
  background-color: teal;
}

.progresspurple {
  background-color: #9b59b6;
}

.progressorange {
  background-color: #ffa500;
}

.progressgreen {
  background-color: #27ae60;
}

/* Keyframes for Progress Bars */
@keyframes progress-html {
  0% {
    width: 0%;
  }

  100% {
    width: 95%;
  }
}

@keyframes progress-css {
  0% {
    width: 0%;
  }

  100% {
    width: 93%;
  }
}

@keyframes progress-javascript {
  0% {
    width: 0%;
  }

  100% {
    width: 85%;
  }
}

@keyframes progress-react {
  0% {
    width: 0%;
  }

  100% {
    width: 85%;
  }
}

@keyframes progress-python {
  0% {
    width: 0%;
  }

  100% {
    width: 95%;
  }
}

@keyframes progress-golang {
  0% {
    width: 0%;
  }

  100% {
    width: 78%;
  }
}

.box-device {
  background: #230035;
  box-shadow: inset 0 0 10px rgba(106, 0, 117, 0.623);
  border-radius: 10px;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.9)
}

#scroll-slide::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 15px;
}

#scroll-slide::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
  border-radius: 15px;
}

#scroll-slide::-webkit-scrollbar-thumb {
  background-color: #3c0053;
  border-radius: 15px;
}